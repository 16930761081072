import React, { useContext, useEffect } from "react"
import get from "lodash/get"
import Modal from "react-modal"
import Helmet from "react-helmet"

import { LangContext } from "../../context/LangContext"

import { useWindowScroll } from "../../hooks/useWindowScroll"

import { useContactData } from "../../service/contact.service"
import { useGlobalMetaTags } from "../../service/global-meta-tags.service"
import { useNavigationBlogData } from "../../service/navigation-blog.service"
import { useOgIndexData } from "../../service/og-index.service"
import { renderGlobalMetaTags } from "../../utils/renderGlobalMetaTags"

import Navigation from "../../components/containers/Naviagtion/Navigation"

import styles from "./BlogLayout.module.scss"

const KenticoBlogData = lang => ({
  contactViewData: useContactData(lang),
  navigationViewData: useNavigationBlogData(lang),
})

const BlogLayout = props => {
  const isScrolledFromTop = useWindowScroll()
  const { pageLang } = useContext(LangContext)

  useEffect(() => {
    Modal.setAppElement("#___gatsby")
  }, [])

  const { navigationViewData = {}, children } = props

  const og = useOgIndexData(pageLang)
  const { ogIndexDescription, ogIndexImg, ogIndexTitle } = og
  const seo = get(children, "props.postDetails.seo", {})
  const { metaTitle, metaDescription, metaImage } = seo
  const globalMetaTags = useGlobalMetaTags(pageLang)
  const url = typeof window !== 'undefined' && window.location.href

  return (
    <>
      <Helmet>
        <title>{metaTitle || ogIndexTitle}</title>
        <meta
          name="description"
          content={metaDescription || ogIndexDescription}
        ></meta>
        <meta property="og:type" content="website" />
        <meta property="og:title" content={metaTitle || ogIndexTitle} />
        <meta
          property="og:description"
          content={metaDescription || ogIndexDescription}
        />
        <meta property="og:image" content={metaImage || ogIndexImg} />
        {url && (
          <link
            rel="canonical"
            href={url}
          />
        )}
        {renderGlobalMetaTags(globalMetaTags)}
      </Helmet>
      <div className={styles.MainContainer}>
        <div
          className={
            isScrolledFromTop
              ? `${styles.HeaderWrapper} ${styles.Scrolled}`
              : `${styles.HeaderWrapper}`
          }
        >
          <Navigation
            elements={navigationViewData.navElements}
            scrolled={isScrolledFromTop}
            buttonSlug={navigationViewData.contact}
            logo={navigationViewData.logo}
            alignLeft={true}
            hasLangSelection={true}
            langActive={pageLang}
          />
        </div>
        <div className={styles.ContentWrapper}>{children}</div>
      </div>
    </>
  )
}

export default props => {
  const { pageLang } = useContext(LangContext)
  const data = KenticoBlogData(pageLang)
  const newProps = { ...data, ...props }
  return <BlogLayout {...newProps} />
}
