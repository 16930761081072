import { graphql, useStaticQuery } from "gatsby"
import {
    filterDataByLanguage,
  getTextData,
  getPictureUrl,
} from "./serviceHelpers"

export const useOgIndexData = lang => {
  const {  allKontentItemOg } = useStaticQuery(
    graphql`
      query OgIndexQuery {
        allKontentItemOg {
            nodes {
              elements {
                metadescription {
                  value
                }
                metaimg {
                  value {
                    url
                  }
                }
                metatitle {
                  value
                }
              }
              preferred_language
            }
          }
      }
    `
  )

  const ogIndexQueryData = filterDataByLanguage(
    allKontentItemOg,
    lang
  )

  let ogIndexData = {
    ogIndexImg: getPictureUrl(ogIndexQueryData.metaimg),
    ogIndexTitle: getTextData(ogIndexQueryData.metatitle),
    ogIndexDescription: getTextData(ogIndexQueryData.metadescription),
  }

  return ogIndexData
}
