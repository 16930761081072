import React, { useState } from 'react'
import Collapsible from 'react-collapsible'

import styles from './Dropdown.module.scss'

const Dropdown = ({ categories = [], onChangeCategory, initialCategory } = {}) => {
  const [isOpen, handleCollapse] = useState(false)
  const [options, setOptions] = useState(categories.filter(item => item !== initialCategory))
  const [category, setCategory] = useState(initialCategory)

  const changeCategory = (category) => {
    setCategory(category)
    setOptions(categories.filter(option => option !== category))
    onChangeCategory(category)
    handleCollapse(false)
  }

  return (
    <div className={styles.DropdownWrapper}>
      <div
        className={styles.Dropdown}
        onClick={() => handleCollapse(prev => !prev)}
      >
        <span className={styles.CategoryItem}>{ category }</span>
        <div className={styles.ArrowWrapper}>
          <div className={isOpen ? styles.ArrowUp : styles.ArrowDown} />
        </div>
      </div>
      <Collapsible open={isOpen} transitionTime={200}>
        <ul className={styles.OptionsWrapper}>
          {
            options.map(option =>
              <li
                className={styles.CategoryItem}
                key={option}
                onClick={() => changeCategory(option)}
              >
                { option }
              </li>
            )
          }
        </ul>
      </Collapsible>
    </div>
  )
}

export default Dropdown