import React, {useContext} from "react"
import get from "lodash/get"
import { Link } from "gatsby"

import { LangContext } from "../../../context/LangContext"

import BlogPostCard from "../BlogPostCard/BlogPostCard"

import styles from './PostsList.module.scss'

const GetLang = () => {
  const {pageLang} = useContext(LangContext);
  return pageLang !== 'en' ? pageLang : '';
}

const PostsList = ({ blogPosts } = []) => (
  <ul className={styles.ListWrapper}>
    {
      blogPosts.map(post => {
        const title = get(post, 'elements.title.value')
        const imgUrl = get(post, 'elements.image.value[0].url')
        const summary = get(post, 'elements.summary.value')
        const slug = get(post, 'elements.basic_page_settings__url.value')
        const category = get(post, 'elements.blog_category.linked_items[0].elements.blog_category_name.value')
        return (
          <li
            key={slug}
            className={styles.ListItem}
          >
            <Link
              className={styles.Link}
              to={`${GetLang()}/blog/${category.replace(/\s+/g, '-').toLowerCase()}/${slug}`}
            >
              <BlogPostCard
                title={title}
                imgUrl={imgUrl}
                summary={summary}
                category={category}
              />
            </Link>
          </li>
        )
      })
    }
  </ul>
)

export default PostsList