import { graphql, useStaticQuery } from "gatsby"
import get from 'lodash/get'

export const useBlogData = lang => {
  const { allKontentItemBlogArticle } = useStaticQuery(
    graphql`
      query BlogQuery {
        allKontentItemBlogArticle(sort: {fields: elements___publication_date___value, order: DESC}) {
          nodes {
            elements {
              publication_date {
                value
              }
              title {
                value
              }
              basic_page_settings__url {
                value
              }
              image {
                value {
                  url
                }
              }
              blog_category {
                linked_items {
                  elements {
                    blog_category_name {
                      value
                    }
                  }
                }
              }
              summary {
                value
              }
            }
            preferred_language
            system {
              language
            }
          }
        }
      }
    `
  )

  const blogQueryData =
    get(allKontentItemBlogArticle, 'nodes', [])
      .filter(item => item.system.language === lang && item.preferred_language === lang)

  return blogQueryData
}
