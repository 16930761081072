import { graphql, useStaticQuery } from "gatsby"
import get from 'lodash/get'

export const useBlogCategoryData = lang => {
  const { allKontentItemBlogCategory } = useStaticQuery(
    graphql`
      query BlogCategoryQuery {
        allKontentItemBlogCategory {
          nodes {
            elements {
              blog_category_name {
                value
              }
            }
            preferred_language
          }
        }
      }
    `
  )

  const blogCategoryQueryData =
    get(allKontentItemBlogCategory, 'nodes', [])
      .filter(item => item.preferred_language === lang)

  return blogCategoryQueryData
}
