import React, { useState, useEffect, useContext } from "react"
import get from 'lodash/get'

import { LangContext } from "../../context/LangContext"

import Dropdown from "../../components/blocks/Dropdown/Dropdown"
import PostsList from "../../components/containers/PostsList/PostsList"
import Pagination from "../../components/blocks/Pagination/Pagination"
import { POSTS_PER_PAGE } from "../../utils/consts"

import styles from './BlogListView.module.scss'

const BlogListView = ({ posts = [], categories = [], blogCategory } = {}) => {
  const {pageLang} = useContext(LangContext);
  const ALL_CATEGORIES = pageLang === 'en' ? 'All' : 'Wszystko'

  const [category, setCategory] = useState(blogCategory || ALL_CATEGORIES)
  const [blogPosts, setBlogPosts] = useState(posts)
  const [currentPage, setCurrentPage] = useState(1)
  const parsedCategories = [ALL_CATEGORIES, ...categories.map(category => get(category, 'elements.blog_category_name.value'))]
  const onChangeCategory = (category) => setCategory(category)

  const setPostsWithPagination = (currentPage, posts) =>
    posts.slice((currentPage - 1) * POSTS_PER_PAGE, currentPage * POSTS_PER_PAGE)

  useEffect(() => {
    if (category === ALL_CATEGORIES) {
      setBlogPosts(posts)
      setCurrentPage(1)
      return
    }
    setBlogPosts(posts.filter(post => get(post, 'elements.blog_category.linked_items[0].elements.blog_category_name.value') === category))
    setCurrentPage(1)

  }, [category, posts, ALL_CATEGORIES])
  return (
    <div>
      <div>
        <Dropdown
          categories={parsedCategories}
          initialCategory={category}
          onChangeCategory={onChangeCategory}
        />
      </div>
      <div className={styles.ListWrapper}>
        <PostsList blogPosts={setPostsWithPagination(currentPage, blogPosts)} />
        <Pagination
          postsNumber={blogPosts.length}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </div>
  )
}

export default BlogListView
