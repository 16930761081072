import React from "react"
import root from "window-or-global"

import { POSTS_PER_PAGE } from "../../../utils/consts"

import styles from './Pagination.module.scss'

const Pagination = ({ postsNumber, setCurrentPage, currentPage }) => {
  if (postsNumber <= POSTS_PER_PAGE) return null

  const pagesCount = Math.ceil(postsNumber / POSTS_PER_PAGE)

  const setPage = (pageNumber) => {
    setCurrentPage(pageNumber)
    root.scrollTo(0, 0)
  }

  return (
    <div className={styles.Container}>
      {
        Array.from({length: pagesCount}, (v, k) => k + 1)
          .map(pageNumber => (
            <span
              key={pageNumber}
              onClick={() => setPage(pageNumber)}
              className={`${styles.PageNumber} ${pageNumber === currentPage ? styles.PageActive : ''}`}
            >
								{pageNumber}
							</span>
          ))
      }
    </div>
  )
}

export default Pagination
