import React from "react"

import PostCategoryBadge from "../../blocks/PostCategoryBadge/PostCategoryBadge"

import styles from './BlogPostCard.module.scss'

const BlogPostCard = ({ title, imgUrl, summary, category } = {}) =>
  <>
    <div className={styles.ImageContainer}>
      <img
        className={styles.Img}
        src={imgUrl}
        alt={title} />
      <PostCategoryBadge
        className={styles.BlogCategory}
        category={category}
      />
    </div>
    <h2 className={styles.BlogTitle}>{ title }</h2>
    <p className={styles.BlogSummary}>{ summary }</p>
  </>

export default BlogPostCard