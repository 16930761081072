import { graphql, useStaticQuery } from "gatsby"
import { filterDataByLanguage, getApplePersons, getCompanySizeData, getRichTextData, getPerson, getTextData } from "./serviceHelpers"
export const useContactData = lang => {
  const { allKontentItemMainComponent } = useStaticQuery(
    graphql`
      query contactQuery {
        allKontentItemMainComponent {
          nodes {
            preferred_language
            elements {
              contact_header {
                resolvedData {
                  html
                }
              }
              contact_description {
                value
              }
              contact_apple_person {
                linked_items {
                  elements {
                    apple_person_name {
                      value
                    }
                    apple_person_position {
                      value
                    }
                    icon {
                      value {
                        url
                      }
                    }
                    percent {
                      value
                    }
                    three_text {
                      resolvedData {
                        html
                      }
                    }
                  }
                }
              }
              contact_person {
                linked_items {
                  elements {
                    company {
                      value
                    }
                    name_and_surname {
                      value
                    }
                    person_picture {
                      value {
                        url
                      }
                    }
                    person_position {
                      value
                    }
                  }
                }
              }
              complete_form_placeholder {
                value
              }
              form_warning_information {
                value
              }
              email_placeholder {
                value
              }
              company_size_placeholder {
                value
              }
              company_size_container {
                linked_items {
                  elements {
                    company_size_text {
                      value
                    }
                  }
                }
              }
              policy_agreement {
                value
              }
              button_placeholder {
                value
              }
              message_placeholder {
                value
              }
              contact_data {
                resolvedData {
                  html
                }
              }
            }
          }
        }
      }
    `
  )

  const contactQueryData = filterDataByLanguage(
    allKontentItemMainComponent,
    lang
  )

  let contactData = {
    contactHeader: getRichTextData(contactQueryData.contact_header),
    contactDescription: getTextData(contactQueryData.contact_description),
    warningInformation: getTextData(contactQueryData.form_warning_information),
    completeFormPlh: getTextData(contactQueryData.complete_form_placeholder),
    contactFormData: {
      formMailPlh: getTextData(contactQueryData.email_placeholder),
      companySizePlh: getTextData(contactQueryData.company_size_placeholder),
      companyContainer: getCompanySizeData(
        contactQueryData.company_size_container
      ),
      messagePlh: getTextData(contactQueryData.message_placeholder),
      firstAgreement: getTextData(contactQueryData.policy_agreement),
      buttonPlh: getTextData(contactQueryData.button_placeholder),
    },
    contactPersonData: getPerson(contactQueryData.contact_person),
    contactAppleData: getApplePersons(contactQueryData.contact_apple_person),
    companyData: getRichTextData(contactQueryData.contact_data)
  }

  return contactData
}
